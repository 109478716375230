import React from 'react';
import { render } from 'react-dom';
import AppComponent from './components/Main';
import UploaderComponent from './components/Uploader'
import GalleryComponent from './components/Gallery'
import OptionsComponent from './components/Options'
import "./translations/i18n";

if (document.getElementById('app')) {
    render(
        <AppComponent/>
        ,document.getElementById('app')
    );
}
if(document.getElementById('uploader')) {
    render(
        <UploaderComponent />
        ,document.getElementById('uploader')
    );
}
if(document.getElementById('gallery')) {
    render(
        <GalleryComponent/>
        , document.getElementById('gallery')
    );
}
if(document.getElementById('image-options')) {
    render(
        <OptionsComponent/>
        , document.getElementById('image-options')
    );
}