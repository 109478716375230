import React from 'react';
import UploaderComponent from './Uploader'
import { withTranslation } from "react-i18next";

class AppComponent extends React.Component {

  constructor(props) {
    super(props);
    this.props.i18n.changeLanguage(document.getElementById('current_lang').dataset.lang);
  }

  render() {
    
    return (
      <div>
          <UploaderComponent/>
      </div>
  );
  }
}

AppComponent.defaultProps = {
};

export default withTranslation("translations")(AppComponent);
