import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
  // we init with resources
  resources: {
    en: {
      translations: {
        "info.header":"Remove background from your photos",
        "signUp":"Sign up",
        "info.clipbg":"Clip background of your photo",
        "info.completely":"Completely",
        "info.automatic":"automatic",
        "info.tryIt":"Try it",
        "info.forFree":"for free",
        "info.API":"We have an API",
        "info.checkOut":"Our",
        "info.pricings":"pricings",
        "indexOf.best":"are the best",
        "info2.remove":"Remove background of any photo.",
        "info2.detect":"We detect fore- and background of jpg or png images.",
        "info2.try":"You can try removing background of your photo <strong>for free</strong> and get a preview with about 0.2 MP.",
        "info2.free":"",
        "info2.resolution":" for free and charge Credits to process high resolution photos and to use our API!",
        "Drop here": "Drop photo here",
        "File detected": "File detected",
        "Drop": "Drop",
        "Removing background...":"Removing background...",
        "Working...":"Working...",
        "png and jpg are supported!":"png and jpg are supported!",
        "<strong>Oh snap!</strong>":"<strong>Oh snap!</strong>",
        "<strong>Well done!</strong>":"<strong>Well done!</strong>",
        "Background removed": "Background removed",
        " images in queue": "  image(s) in queue",
        "uploader.browse":"or browse for photo...",
        "original":"Original",
        "preview":"Preview",
        "loading":"Loading",
        "download":"Download",
        "options.options":"Options",
        "options.imgRes":"Resolution of the clipped photo",
        "options.pixels":"pixels longer side",
        "options.protip":"Pro-tip",
        "options.highResProcess": "High resolutions process slower. Make sure, you set the resolution according to your needs!",
        "options.keepImg":"Keep photos",
        "options.save":"Save",
        "megapixels":"megapixels",
        "We'll keep your images.":"We'll keep your photos.",
        "More info":"More info",
        "Your images will be deleted after ~ 1 hour.":"Your photos will be deleted after about 1 hour.",
        "jetzt": ""
      }
    },
    de: {
      translations: {
        "info.header":"Hintergrund von Fotos entfernen",
        "signUp":"Registrieren",
        "info.clipbg":"Freistellen von Fotos",
        "info.completely":"Vollkommen",
        "info.automatic":"automatisch",
        "info.tryIt":"Ausprobieren",
        "info.forFree":"kostet nichts",
        "info.API":"Wir haben eine API",
        "info.checkOut":"Unsere",
        "info.pricings":"Preise",
        "info.best":"sind unschlagbar",
        "info2.remove":"Entferne den Hintergrund von Fotos",
        "info2.detect":"Wir erkennen Vorder- und Hintergrund von jpg- und png-Bildern",
        "info2.try":"Du kannst <strong>gratis</strong> Fotos mit unserer KI freistellen und bekommst eine Vorschau von ca. 0.2 MegaPixel",
        "info2.free":"Kostenlos",
        "info2.resolution":", Credits aufladen und einfach Fotos höherer Auflösungen verarbeiten sowie unsere API nutzen",
        "Drop here": "Foto hier ablegen",
        "File detected": "Datei erkannt",
        "Drop": "Ablegen",
        "Removing background...":"Entferne Hintergrund...",
        "Working...":"Wir arbeiten dran...",
        "png and jpg are supported!":"Es wird png und jpg unterstützt!",
        "Background removed.": "Hintergrund wurde entfernt.",
        " images in queue": " Foto(s) in der Warteschleife",
        "uploader.browse": "oder Foto auswählen...",
        "original":"Original",
        "preview":"Vorschau",
        "loading":"Wird geladen",
        "download":"Herunterladen",
        "options.options":"Einstellungen",
        "options.imgRes":"Auflösung des freigestellten Fotos",
        "options.pixels":"Pixel, längere Seite",
        "options.protip":"Profi-Tipp",
        "options.highResProcess": "Höhere Auflösungen benötigen länger. Stelle die Auflösung so ein, wie du sie brauchst!",
        "options.keepImg":"Fotos behalten",
        "options.save":"Speichern",
        "megapixels":"MegaPixel",
        "We'll keep your images.":"Wir bewahren deine Fotos auf.",
        "More info":"Mehr dazu",
        "Your images will be deleted after ~ 1 hour.":"Deine Fotos werden nach ca. 1 Stunde gelöscht.",
        "choose-color": "Wähle eine Hintergrundfarbe",
        "apply":"Anwenden",
        "modify-background": "Hintergrund ändern",
        "uploader.bgFileUpload": "Hintergrund mit Foto ersetzen",
        "blur": "Hintergrund weichzeichnen",
        "converttogray": "Hintergrund in Graustufen umwandeln",
        "cancel": "Abbrechen",
        "Finished the job": "Wir haben den Job erledigt",
        "shadow":"Schlagschatten",
        "close":"Schließen",
        "signup":"Registrieren",
        "<strong>Well done!</strong> ":"<strong>Alles bestens!</strong> ",
        "<strong>Oh snap!</strong> ": "<strong>Oh nein!</strong> ",
        "timeEstimate":"Zeitschätzung:",
        "seconds":"Sekunden"
      }
    }
  },
  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false
  },
});

export default i18n;
