import React from 'react';
import { withTranslation, Trans } from "react-i18next";
import ImageEditorComponent from './ImageEditor';

class UploaderComponent extends React.Component {

  constructor(props) {
    super(props);
    this.props.i18n.changeLanguage(document.getElementById('current_lang').dataset.lang);
    this.state = {
      status: props.t('Drop here'),
      dropCss: 'DropArea',
      originalImg: null,
      preview: null,
      previewCss: 'ImagePreview Show',
      progressCss: 'ImageProgress', // + ' Show',
      percentage: 0,
      enableDragAndDrop: true,
      xhr: null,
      queryStatus: false,
      transparentResultImg: null,
      res:540
    };
  }

  componentDidMount() {
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        let resp = JSON.parse(xhr.response);
        if (resp.error) {
          // fail silently - the res is not set here and will remain at 540
        } else {          
          if(resp.positive_balance) {
            this.setState({ res: resp.options.res });
          } else {
            this.setState({ res: 540 });
          }
        }
      }
    }
    xhr.open('GET', '/image_options', true);
    xhr.send();
  }

  render() {

    const { t } = this.props;

    const onDragEnter = event => {
      if (this.state.enableDragAndDrop) {
        this.setState({ status: t('File Detected'), dropCss: 'DropArea' });
      }
      event.preventDefault();
      event.stopPropagation();
    }
    const onDragLeave = event => {
      if (this.state.enableDragAndDrop) {
        this.setState({ status: t('Drop here'), dropCss: 'DropArea' });
      }
      event.preventDefault();
    }
    const onDragOver = event => {
      if (this.state.enableDragAndDrop) {
        this.setState({ status: t('Drop'), dropCss: 'DropArea Over' });
      }
      event.preventDefault();
    }

    const onDrop = event => {
      this.setState({ queryStatus: true });
      const supportedFilesTypes = ['image/jpeg', 'image/png'];
      const { type } = typeof event.dataTransfer === 'undefined' ? event.target.files[0] : event.dataTransfer.files[0];
      if (supportedFilesTypes.indexOf(type) > -1 && this.state.enableDragAndDrop) { 
        resizeImg(typeof event.dataTransfer === 'undefined' ? event.target.files[0] : event.dataTransfer.files[0], type)
      } else {
        showError(t('png and jpg are supported!'))
        resetState();
      }
      event.preventDefault();
    }

    const resizeImg = (imgFile, imgType) => {
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({ preview: e.target.result, previewCss: 'ImagePreview Show', progressCss: 'ImageProgress Show' });
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          let settingsWidth = 0,
            settingsHeight = 0;
          if (img.width > img.height) {
            settingsWidth = img.width < this.state.res ? img.width : this.state.res;
            settingsHeight = img.height * (settingsWidth / img.width);
          } else {
            settingsHeight = img.height < this.state.res ? img.height : this.state.res;
            settingsWidth = img.width * (settingsHeight / img.height);
          }
          const canvas = document.createElement('canvas');
          canvas.width = settingsWidth;
          canvas.height = settingsHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, settingsWidth, settingsHeight);
          this.setState({ originalImg: img });
          ctx.canvas.toBlob(
            blob => {
              const resizedImage = new File([blob], 'resizedImg.' + imgType.split('/')[1], {
                type: imgType,
                lastModified: Date.now()
              });
              processUpload(resizedImage);
            },
            imgType,
            1
          );
        }
      }
      reader.readAsDataURL(imgFile);
    }

    const processUpload = (resizedImage) => {
      const payload = new FormData();
      payload.append('file', resizedImage);
      payload.append('csrf_token', document.head.querySelector("[name~=csrf-token][content]").content);
      payload.append('data', JSON.stringify({ bg_type: 'remove' }));
      const xhr = new XMLHttpRequest();
      xhr.addEventListener('error', handleUploadError);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          let resp = JSON.parse(xhr.response);
          if (resp.error) {
            if(xhr.status == 403 && resp.error == 'no uploads left') {
              window.location = '/signup';
            }
            showError(resp.error);
          } else {
            if (resp.message) {
              showMessage(resp.message);
            }
            this.setState({timeEstimate:resp.time_estimate})
            checkStatus(resp.id);
          }
        }
      }
      xhr.upload.onprogress = (e) => {
        const done = e.position || e.loaded
        const total = e.totalSize || e.total;
        const perc = (Math.floor(done / total * 1000) / 10);
        if (perc >= 100) {
          this.setState({ status: t('Working...') });
        } else {
          this.setState({ status: `${perc}%` });
        }
        this.setState({ percentage: perc });
      };
      xhr.open('POST', 'file_upload', true);
      xhr.send(payload);
      this.setState({ enableDragAndDrop: false, xhr: xhr });

    }

    const showError = (error) => {
      document.getElementById('error-alert').innerHTML = t('<strong>Oh snap!</strong> ') + error;
      document.getElementById('error-alert').style.display = 'block';
      setTimeout(() => {
        document.getElementById('error-alert').style.display = 'none';
      }, 5000);
      resetState();
    }

    const showMessage = (message) => {
      document.getElementById('success-alert').innerHTML = t('<strong>Well done!</strong> ') + message;
      document.getElementById('success-alert').style.display = 'block';
      setTimeout(() => {
        document.getElementById('success-alert').style.display = 'none';
      }, 5000);
    }

    const resetState = () => {
      setTimeout(() => {
        this.setState({
          status: t('Drop here'),
          preview: this.state.originalImg,
          progressCss: 'ImageProgress Show',
          enableDragAndDrop: true
        });
      }, 250);
    }

    const resetAllStates = () => {
      setTimeout(() => {
        this.setState({
          status: this.props.t('Drop here'),
          dropCss: 'DropArea',
          originalImg: null,
          preview: null,
          previewCss: 'ImagePreview Show',
          progressCss: 'ImageProgress', // + ' Show',
          percentage: 0,
          enableDragAndDrop: true,
          xhr: null,
          queryStatus: false,
          img_id:null,
          transparentResultImg:null,
          originalImg:null
        })
      }, 250);
    }

    const checkStatus = (id) => {
      const xhr = new XMLHttpRequest();
      xhr.addEventListener('error', handleUploadError);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          let resp = JSON.parse(xhr.response);
          if (resp.files.length > 0) {            
            if (this.state.transparentResultImg === null) {
              const img = new Image();
              img.src = resp.files[0].path;
              const res = resp.image_payed ? this.state.res : 540;
              img.onload = () => {
                this.setState({ transparentResultImg: img, img_id: resp.files[0].img_id, res:res });
              }
            }
            resetState();
          } else {
            if (resp.queue_size > 0) {
              let q = document.getElementById('queue');
              q.style.display = 'block';
              q.innerHTML = resp.queue_size + t(' images in queue');
            } else {
              let q = document.getElementById('queue');
              q.style.display = 'none';
            }
            if (this.state.queryStatus) {
              setTimeout(() => checkStatus(id), 1000);
            }
          }
        }
      }
      xhr.open('GET', 'status?id=' + id, true);
      xhr.send();
    }

    const handleUploadError = () => {
      console.error('Upload Error');
    }

    const onAbortClick = () => {
      resetAllStates();
    };

    let imageEditor = null;
    if(this.state.originalImg) {
      imageEditor = <ImageEditorComponent originalImg={ this.state.originalImg } transparentResultImg={ this.state.transparentResultImg } res={ this.state.res } img_id={ this.state.img_id } onClose={ resetAllStates } timeEstimate={ this.state.timeEstimate}/>;
    }
    return (

      <div>
        <div className='App shadow' onDragEnter={onDragEnter}>
          <div className={this.state.dropCss + ' ' + (this.state.status === 'Done' ? 'Uplading' : '')} onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave}>
            <div className={this.state.previewCss} style={{ top: 0 }}>
              <div className='ImageProgressImage' style={{ backgroundImage: `url(${this.state.preview})`, filter: 'none' }}></div>
              <div className='ImageProgressUploaded' style={{ backgroundImage: `url(${this.state.preview})`, clipPath: `inset(${100 - Number(this.state.percentage)}% 0 0 0)` }}></div>
            </div>
            <div className={'Status ' + (this.state.status.indexOf('%') > -1 || this.state.status === 'Done' ? 'Uplading' : '')}>{this.state.status}</div>
            {this.state.status.indexOf('%') > -1 && <div className='Abort' onClick={onAbortClick}><span>&times;</span></div>}
            <label htmlFor="fileUpload" className="file-upload btn btn-secondary btn-block shadow align-self-end" style={{ zIndex: 100, margin: '2% 20%' }}>
              <i className="fa fa-upload mr-2"></i><Trans i18nKey="uploader.browse">or browse for photo...</Trans>
              <input id="fileUpload" type="file" style={{ display: 'none' }} onChange={onDrop} />
            </label>
          </div>
        </div>
        {imageEditor}
      </div>
    );
  }
}

export default withTranslation("translations")(UploaderComponent);
