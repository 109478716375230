import React from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import ImageEditorComponent from './ImageEditor';

class GalleryComponent extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      hasMore: true,
      img_id:null,
      originalImg:null,
      transparentResultImg:null,
      res:540
    };
    this.fetchMoreData();
  }

  handleXHRError = () => {
    console.error('Error fetching images.');
  }

  fetchMoreData = () => {
    setTimeout(() => {
      let xhr = new XMLHttpRequest();
      xhr.addEventListener('error', this.handleXHRError);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          let resp = JSON.parse(xhr.response);
          if(resp.files.length > 0) {
            for(let i = 0; i < resp.files.length; i++) {
              let img_id = resp.files[i].id;
              let card = document.getElementById('img-' + img_id);
              if(typeof card !== 'undefined' && card !== null) {
                this.deleteImgFromDom(img_id);
              }
            }
            this.setState({images: this.state.images.concat(resp.files)})
          } else {
            this.setState({hasMore:false})
          }
        }
      }
      if(document.getElementById('user_id')) {
        let user_id = document.getElementById('user_id').innerHTML;
        xhr.open('GET', '/fetch_images/' + this.state.images.length + '/' + user_id, true);
      }
      else {
        xhr.open('GET', '/fetch_images/' + this.state.images.length, true);
      }
      xhr.send();
    }, 150);
  };

  deleteImg = (img_id) => {    
    let xhr = new XMLHttpRequest();
    xhr.addEventListener('error', this.handleXHRError);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        let resp = JSON.parse(xhr.response);
        if(resp.error) {
          console.log(resp.error);
        } 
        this.deleteImgFromDom(img_id);
      }
    }
    const payload = new FormData();
    payload.append('csrf_token', document.head.querySelector("[name~=csrf-token][content]").content);
    payload.append('img_id', img_id);
    xhr.open('POST', 'user_image_delete', true);
    xhr.send(payload);
  };

  deleteImgFromDom = (img_id) => {
    let elem = document.getElementById('img-' + img_id);
    elem.parentNode.removeChild(elem);
  }

  rateImage = (img_id, rating) => {
    let xhr = new XMLHttpRequest();
    xhr.addEventListener('error', this.handleXHRError);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        let resp = JSON.parse(xhr.response);
        if(resp.error) {
          console.log(resp.error);
        } 
      }
    }
    const payload = new FormData();
    payload.append('csrf_token', document.head.querySelector("[name~=csrf-token][content]").content);
    payload.append('img_id', img_id);
    payload.append('rating', rating);
    xhr.open('POST', 'user_image_rate', true);
    xhr.send(payload);
  }

  downloadImage = (img_id) => {      
    var x = new XMLHttpRequest();
    x.open("GET", "/download_image/"+img_id, true);
    x.responseType = 'blob';      
    x.onload=function(e){download(x.response, img_id + '.png', "image/png" ); }
    x.send();
  }

  editImage = (img_id) => {
    const oImg = new Image();
    oImg.src = "/download_image_original/" + img_id;
    oImg.onload = () => {
      this.setState({ originalImg: oImg, img_id:img_id });
      const img = new Image();
      img.src = "/download_image/" + img_id;        
      img.onload = () => {      
        this.setState({ transparentResultImg: img, img_id:img_id, res: img.width > img.height ? img.width : img.height });
      }
    }    
  }

  resetAllStates = () => {
    this.setState({
      img_id:null,
      originalImg:null,
      transparentResultImg:null,
      res:540
    })
  }

  render() {

    let imageEditor = null;
    if(this.state.originalImg) {
      imageEditor = <ImageEditorComponent originalImg={ this.state.originalImg } transparentResultImg={ this.state.transparentResultImg } res={ this.state.res } img_id={ this.state.img_id } onClose={ this.resetAllStates } />;
    }

    return (
      <div>
          <InfiniteScroll
            dataLength={this.state.images.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={''}
          >
            {this.state.images.map((i, index) => (
              <div key={this.state.images[index].img_id} className="card" id={'img-' + this.state.images[index].img_id}>
                <img className="card-image w-100" src={this.state.images[index].path}/>
                <div className="card-img-overlay">
                <span className="btn-sm btn-dark" style={{cursor:'pointer'}} onClick={() => { this.editImage(this.state.images[index].img_id)}}><span className="fa fa-edit"></span></span>
                    <span className="btn-sm btn-dark" style={{cursor:'pointer'}} onClick={() => { this.downloadImage(this.state.images[index].img_id)}}><span className="fa fa-cloud-download"></span></span>
                    <span className="btn-sm btn-dark" style={{cursor:'pointer'}} onClick={() => { this.deleteImg(this.state.images[index].img_id)} }><span className="fa fa-trash"></span></span>
                    <span className="ml-2 inline-block">
                    <Rater total={5} rating={this.state.images[index].rating} onRate={ rating => this.rateImage(this.state.images[index].img_id, rating.rating)} />
                    </span>
                </div>
              </div>
            ))}
          </InfiniteScroll>
          {imageEditor}
        </div>
    );
  }
}

export default GalleryComponent;