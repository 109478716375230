import React from 'react';
import { withTranslation, Trans } from "react-i18next";

class OptionsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.props.i18n.changeLanguage(document.getElementById('current_lang').dataset.lang);
        this.state = {
            res:1920,
            currentBalance:0,
            saveImages:true
        }
    }

    t = (string) => {
        return this.props.t(string)
    }

    componentDidMount() {
        let xhr = new XMLHttpRequest();
        xhr.addEventListener('error', this.handleError);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                let resp = JSON.parse(xhr.response);
                if(resp.error) {
                    this.showError(resp.error);
                } else {
                    this.setState({res:resp.options.res, saveImages:resp.options.save_images ? 'checked' : ''});
                }
                setTimeout(this.handleChange, 500);
            }
        }
        xhr.open('GET', '/image_options', true);
        xhr.send();
    }

    handleChange = () => {
        let res = document.getElementById('res').value;
        this.setState({res:res});
        this.checkMP();
        let si = document.getElementById('save-images').checked;
        this.setState({saveImages:si});
        this.checkSaveImages();
    }

    checkMP = () => {
        let mpNode = document.getElementById('mp');
        let res = parseInt(document.getElementById('res').value);
        let mp = (res * res * 0.75 / 1000000).toFixed(2);
        mpNode.innerHTML = '~ ' + mp + ' ' + this.t('megapixels')
    }

    checkSaveImages = () => {
        let siNode = document.getElementById('si');
        let checked = document.getElementById('save-images').checked
        let siText = checked ? this.t("We'll keep your images.") +" <a href='/about' target='_blank'>" + this.t("More info") + "<a>" : this.t("Your images will be deleted after ~ 1 hour.");
        siNode.innerHTML = siText;
    }
    
    handleError = () => {
        console.log('Error posting image options')
    }

    showError = (error) => {
        document.getElementById('error-alert').innerHTML = this.t('<strong>Oh snap!</strong>') + ' ' + error;
        document.getElementById('error-alert').style.display = 'block';
        setTimeout(()=> {
          document.getElementById('error-alert').style.display = 'none';
        }, 5000);
      }
  
      showMessage = (message) => {
        document.getElementById('success-alert').innerHTML = this.t('<strong>Well done!</strong>') + ' ' + message;
        document.getElementById('success-alert').style.display = 'block';
        setTimeout(()=> {
          document.getElementById('success-alert').style.display = 'none';
        }, 5000);
      }

    sendOptions = () => {
        let xhr = new XMLHttpRequest();
        xhr.addEventListener('error', this.handleError);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                let resp = JSON.parse(xhr.response);
                if(resp.error) {
                    this.showError(resp.error);
                } else {
                    this.showMessage(resp.message);
                }
            }
        }
        const payload = new FormData();
        payload.append('res', this.state.res);
        payload.append('save_images', this.state.saveImages);
        payload.append('csrf_token', document.head.querySelector("[name~=csrf-token][content]").content);
        xhr.open('POST', 'image_options', true); 
        xhr.send(payload);
    }

    render() {

        return (
        <div>
            <div className="jumbotron shadow">
                <h2 className="display-8"><Trans i18nKey="options.options">Options</Trans></h2>
                <hr className="my-2"/>
                <div>
                    <Trans i18nKey="options.imgRes">Image resolution</Trans>
                    <input type="range" min="540" step="20" max="4160" value={this.state.res} className="slider" id="res" onChange={this.handleChange} onInput={this.handleChange}/>
                    <div><span id="mp" className="d-inline font-weight-bold"></span> ({this.state.res} <Trans i18nKey="options.pixels">pixels longer side</Trans>)</div>
                    <div><span className="font-italic"><Trans i18nKey="options.protip">Pro-tip</Trans>:</span> <Trans i18nKey="options.highResProcess">High resolutions process slower. Make sure, you set the resolution according to your needs!</Trans></div>
                </div>
                <hr className="my-2"/>
                <div className="custom-control custom-checkbox p-4">
                    <input type="checkbox" className="custom-control-input" id="save-images" checked={this.state.saveImages} onChange={this.handleChange}/>
                    <label className="custom-control-label" htmlFor="save-images"><Trans i18nKey="options.keepImg">Keep images</Trans></label>
                </div>
                <div><span id="si"></span></div>
                <hr className="my-2"/>
                <div className="d-flex justify-content-end">
                    <button onClick={this.sendOptions} className="btn btn-primary"><Trans i18nKey="options.save">Save</Trans></button>
                </div>
            </div>
        </div>
        );
    }
}

export default withTranslation("translations")(OptionsComponent);