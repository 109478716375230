import React from 'react';
import { withTranslation, Trans } from "react-i18next";
import { SketchPicker } from 'react-color';

class ImageEditorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.props.i18n.changeLanguage(document.getElementById('current_lang').dataset.lang);        
        this.state = {
            previewCanvasOpacity: 0,
            background: 'rgba(0, 0, 0, 0)',
            color: '0, 0, 0, 0',
            transparentBg: `url('/images/transparent_bg.png')`,
            cutoutImage: null,
            res: props.res,
            grayscale: 0,
            blur: 0,
            shadowX: 0,
            shadowY: 0,
            rawShadowX: 0,
            rawShadowY: 0,
            shadowBlur: 10,
            backgroundImage: null,
            hasNewBackgroundImage: false,
            hasFilter: false,
            hasShadow: false
        };
    }

    componentDidMount = () => {
        const loggedIn = document.getElementById('gallery_link');
        this.setState({isLoggedIn:loggedIn !== null})
        this.openEditor();
    }

    componentWillReceiveProps(props){
        if(props.transparentResultImg !== null) {
            this.setState({cutoutImage:props.transparentResultImg});
            setTimeout(()=> {
                this.clearCanvas();
                this.drawCutoutImage();
            } ,250);
        }
        this.state.res = props.res;
        if(!$('#resultModalCentered').is(':visible')) {
            this.openEditor();        
        }
    }

    openEditor = () => {
        jQuery('#resultModalCentered').modal({ show: true, backdrop: 'static', keyboard: false });
        const applyShadow = this.applyShadow;
        $("#marker").draggable({
            containment: "#markerbounds",
            drag: function () {
                slider.get_position(applyShadow);
            },
        });
        let imgEdit = document.getElementById('img-edit');
        slider.draw(150, 150, 1, 1, 25, 2);
        let width = 0,
            height = 0;
        if (this.props.originalImg.width > this.props.originalImg.height || imgEdit.offsetWidth < 540) {
            width = imgEdit.offsetWidth < 540 ? imgEdit.offsetWidth : 540;
            height = this.props.originalImg.height * (width / this.props.originalImg.width);            
        } else {
            height = 540;
            width = this.props.originalImg.width * (height / this.props.originalImg.height);            
        }
        const previewCanvas = document.getElementById('result-img');
        const containerInner = document.getElementById('result-img-container');
        previewCanvas.width = width;
        previewCanvas.height = height;
        previewCanvas.style.position = 'absolute';
        const pctx = previewCanvas.getContext('2d');
        pctx.drawImage(this.props.originalImg, 0, 0, width, height);
        const container = containerInner.parentElement;
        imgEdit.style.minHeight = height + 'px';
        const m = '' + ((((container.offsetHeight - height) / 4) / container.offsetHeight) * 100) + '% ' + ((((container.offsetWidth - width) / 2) / container.offsetWidth) * 100) + '%';
        const pw = '' + (width / container.offsetWidth * 100) + '%';
        const ph = '' + (height / container.offsetHeight * 100) + '%';
        previewCanvas.style.margin = 0;
        previewCanvas.style.opacity = 0.2;
        previewCanvas.style.width = '100%';
        previewCanvas.style.height = '100%';
        containerInner.style.margin = m;
        containerInner.style.width = pw;
        containerInner.style.height = ph;
    }

    clearCanvas = () => {
        const previewCanvas = document.getElementById('result-img');
        const ctx = previewCanvas.getContext('2d');
        ctx.clearRect(0, 0, previewCanvas.width, previewCanvas.height);
    }

    handleChangeComplete = (color) => {
        let colorStr = '' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a
        this.setState({
            color: colorStr,
            background: 'rgba(' + colorStr + ')'
        });
        this.applyFilter();
    }

    filterInput = () => {
        this.setState({ hasFilter: true })
        this.applyFilter();
    }

    applyFilter = () => {
        const previewCanvas = document.getElementById('result-img');
        const ctx = previewCanvas.getContext('2d');
        ctx.clearRect(0, 0, previewCanvas.width, previewCanvas.height);
        if (this.state.hasFilter) {
            const blurFactor = document.getElementById('blur').value;
            const graysacleFactor = document.getElementById('grayscale').value;
            ctx.filter = 'blur(' + blurFactor + 'px) grayscale(' + graysacleFactor + '%)';
            this.setState({ blur: blurFactor, grayscale: graysacleFactor });
            const img = this.state.hasNewBackgroundImage ? this.state.backgroundImage : this.props.originalImg;
            if (this.state.hasNewBackgroundImage) {
                let width = 0,
                    height = 0;
                if (img.width > img.height || imgEdit.offsetWidth < 540) {
                    width = imgEdit.offsetWidth;
                    height = img.height * (width / img.width);
                    while (height < previewCanvas.height) {
                        width++;
                        height = img.height * (width / img.width);
                    }
                } else {
                    height = 540;
                    width = img.width * (height / img.height);
                    while (width < previewCanvas.width) {
                        height++;
                        width = img.width * (height / img.height);
                    }
                }
                const x = parseInt((previewCanvas.width - width) / 2);
                const y = parseInt((previewCanvas.height - height) / 2);
                ctx.drawImage(img, x, y, width, height);
            } else {
                ctx.drawImage(img, 0, 0, previewCanvas.width, previewCanvas.height);
            }
        }
        ctx.fillStyle = this.state.background;
        ctx.fillRect(0, 0, previewCanvas.width, previewCanvas.height);
        this.drawCutoutImage();
    }

    applyShadow = (x, y) => {
        this.setState({ hasShadow: true });
        const previewCanvas = document.getElementById('result-img');
        const ctx = previewCanvas.getContext('2d');
        ctx.clearRect(0, 0, previewCanvas.width, previewCanvas.height);
        const shadowX = (x - 0.5) * (previewCanvas.width / 10);
        const shadowY = (y - 0.5) * -(previewCanvas.height / 10);
        this.setState({ shadowX: shadowX, shadowY: shadowY, rawShadowX: x, rawShadowY: y });
        this.applyFilter();
    }

    drawCutoutImage = () => {
        const previewCanvas = document.getElementById('result-img');
        const ctx = previewCanvas.getContext('2d');
        ctx.filter = 'none';
        if (this.state.hasShadow) {
            ctx.filter = 'drop-shadow(' + this.state.shadowX + 'px ' + this.state.shadowY + 'px ' + this.state.shadowBlur + 'px black)';
        }
        ctx.drawImage(this.state.cutoutImage, 0, 0, previewCanvas.width, previewCanvas.height);
    }

    reset = () => {
        this.setState({ 
            background: 'rgba(0,0,0,0)', 
            blur: 0, 
            grayscale: 0, 
            hasFilter: false, 
            hasNewBackgroundImage: false, 
            hasShadow: false,
            shadowX:0,
            shadowY:0,
            rawShadowX:0,
            rawShadowY:0
        });
        
        const previewCanvas = document.getElementById('result-img');
        const ctx = previewCanvas.getContext('2d');
        ctx.clearRect(0, 0, previewCanvas.width, previewCanvas.height);
        setTimeout(this.drawCutoutImage, 250);
    }

    bgFileUpload = () => {
        const supportedFilesTypes = ['image/jpeg', 'image/png'];
        const { type } = typeof event.dataTransfer === 'undefined' ? event.target.files[0] : event.dataTransfer.files[0];
        if (supportedFilesTypes.indexOf(type) > -1) {
            const reader = new FileReader();
            reader.onload = e => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    this.setState({ backgroundImage: img, hasNewBackgroundImage: true, hasFilter: true });
                    this.applyFilter();
                }
            }
            reader.readAsDataURL(typeof event.dataTransfer === 'undefined' ? event.target.files[0] : event.dataTransfer.files[0]);
        }
    }

    downloadImage = () => {
        const canvas = document.createElement('canvas');
        const img = new Image();
        img.src = "/download_image/" + this.props.img_id;
        img.crossOrigin = "anonymous";
        img.onload = () => {
            let width = 0,
                height = 0;
            if (img.width > img.height) {
                width = this.state.res > img.width ? img.width : this.state.res;
                height = img.height * (width / img.width);
            } else {
                height = this.state.res > img.height ? img.height : this.state.res;
                width = img.width * (height / img.height);
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            if (this.state.hasFilter) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                const blurFactor = document.getElementById('blur').value;
                const graysacleFactor = document.getElementById('grayscale').value;
                ctx.filter = 'blur(' + blurFactor + 'px) grayscale(' + graysacleFactor + '%)';
                this.setState({ blur: blurFactor, grayscale: graysacleFactor });
                const img = this.state.hasNewBackgroundImage ? this.state.backgroundImage : this.props.originalImg;
                if (this.state.hasNewBackgroundImage) {
                    let bgHeight = img.height,
                        bgWidth = img.width;
                    if (bgWidth > bgHeight) {
                        bgWidth = this.state.res;
                        bgHeight = img.height * (bgWidth / img.width);
                        while (bgHeight < canvas.height) {
                            bgWidth++;
                            bgHeight = img.height * (bgWidth / img.width);
                        }
                    } else {
                        bgHeight = this.state.res;
                        bgWidth = img.width * (bgHeight / img.height);
                        while (bgWidth < canvas.width) {
                            bgHeight++;
                            bgWidth = img.width * (bgHeight / img.height);
                        }
                    }
                    const x = parseInt((canvas.width - bgWidth) / 2);
                    const y = parseInt((canvas.height - bgHeight) / 2);
                    ctx.drawImage(img, x, y, bgWidth, bgHeight);
                } else {
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                }                
            }
            ctx.fillStyle = this.state.background;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            if (this.state.hasShadow) {
                const shadowX = (this.state.rawShadowX - 0.5) * (canvas.width / 10);
                const shadowY = (this.state.rawShadowY - 0.5) * -(canvas.height / 10);
                ctx.filter = 'drop-shadow(' + shadowX + 'px ' + shadowY + 'px ' + this.state.shadowBlur + 'px black)';
            } else {
                ctx.filter = 'none';
            }
            ctx.drawImage(img, 0, 0, width, height);
            ctx.canvas.toBlob(
                blob => {
                    const resizedImage = new File([blob], 'clippingphotos.ludicdrive.com.png', {
                        type: 'png',
                        lastModified: Date.now()
                    });
                    download(resizedImage, 'clippingphotos.ludicdrive.com.png', "image/png");
                },
                'png',
                1
            );
        }
    }

    onAbortClick = () => {
        this.setState({
            background: 'rgba(0, 0, 0, 0)',
            color: '0, 0, 0, 0',
            transparentBg: `url('/images/transparent_bg.png')`,
            cutoutImage: null,
            res: 540,
            grayscale: 0,
            blur: 0,
            shadowX: 0,
            shadowY: 0,
            shadowBlur: 0,
            rawShadowX: 0,
            rawShadowY: 0,
            backgroundImage: null,
            hasNewBackgroundImage: false,
            hasFilter: false,
            hasShadow: false
        })
        jQuery('#resultModalCentered').modal('hide');
        setTimeout(() => {
            this.props.onClose();
        }, 250);
    }

    render() {

        const pickerStyle = {
            default: {
                picker: {
                    width: ''
              }
            }
        };

        let loadingOrDownload;
        let previewCanvasOpacity;
        let headerStatus;
        if (this.state.cutoutImage) {
            if(this.state.isLoggedIn) {
                loadingOrDownload = <button onClick={this.downloadImage} id="downloadButton" className="btn btn-primary" download><span className="fa fa-cloud-download"></span> <Trans i18nKey="download">Download</Trans></button>;
            } else {
                loadingOrDownload = <a className="btn btn-primary" href="/signup"><Trans i18nKey="signup">Sign up</Trans></a>
            }            
            previewCanvasOpacity = 1;
            headerStatus = <span><Trans i18nKey="Background removed.">Background removed.</Trans></span>;
        } else {
            loadingOrDownload = <button href="#" id="loadingButton" className="btn btn-primary" disabled>
                                    <span className="spinner-grow spinner-grow-sm" style={{marginBottom:'2px'}}  role="status" aria-hidden="true"></span>
                                    <span className="sr-only">Loading</span><Trans i18nKey="loading">Loading</Trans>
                                </button>;
            previewCanvasOpacity = 0.2;
            headerStatus = <span><Trans i18nKey="Removing background...">Removing background...</Trans> <span className="spinner-grow spinner-grow-sm" style={{marginBottom:'5px'}} role="status" aria-hidden="true"></span> <Trans i18nKey="timeEstimate">Time estimate: </Trans> {this.props.timeEstimate} <Trans i18nKey="seconds">seconds</Trans></span>;
        } 

        return (

        <div>
            <div className="modal" id="resultModalCentered" tabIndex="-1" role="dialog" aria-labelledby="resultModalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="resultModalCenteredLabel">{headerStatus}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6 justify-content-center" id="img-edit">
                                    <div id="result-img-container" style={{ position: 'absolute', top: 0, left: 0 }}>
                                        <div id="result-img-bg" className="img-fluid border-secondary" style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, backgroundImage: this.state.transparentBg }} />
                                        <canvas id="result-img" style={{ opacity:previewCanvasOpacity }}></canvas>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <Trans i18nKey="shadow">Drop shadow</Trans>
                                        <div id="widget">
                                            <div id="markerbounds">
                                                <div id="box">
                                                    <div id="marker" ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <label htmlFor="bgFileUpload" className="btn btn-sm btn-secondary btn-block shadow align-self-end">
                                        <i className="fa fa-upload mr-2"></i><Trans i18nKey="uploader.bgFileUpload">Replace with photo</Trans>
                                        <input id="bgFileUpload" type="file" style={{ display: 'none' }} onChange={this.bgFileUpload} />
                                    </label>
                                    <br />
                                    <div>
                                        <Trans i18nKey="blur">Blur</Trans>
                                        <input type="range" min="0" step="1" max="10" value={this.state.blur} className="slider" id="blur" onChange={this.filterInput} />
                                    </div>
                                    <div>
                                        <Trans i18nKey="converttogray">Grayscale</Trans>
                                        <input type="range" min="0" step="10" max="100" value={this.state.grayscale} className="slider" id="grayscale" onChange={this.filterInput} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <Trans i18nKey="choose-color">Tint background</Trans>
                                    </div>
                                    <div id="color-picker" className="justify-content-center">
                                        <SketchPicker styles={pickerStyle} color={this.state.background} onChangeComplete={this.handleChangeComplete} />
                                    </div>
                                    <hr />
                                    <div className="btn-group btn-group-sm btn-block" role="group">
                                        <button type="button" className="btn btn-sm btn-secondary btn-block shadow align-self-end" onClick={this.reset}><Trans i18nKey="reset">Reset</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">                            
                            <span id="queue"></span>
                            <button type="button" className="btn btn-secondary" onClick={this.onAbortClick}><Trans i18nKey="close">Close</Trans></button>
                            {loadingOrDownload}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default withTranslation("translations")(ImageEditorComponent);